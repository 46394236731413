//
// https://daveceddia.com/multiple-environments-with-react/

var apiGateway = 'http://localhost:5500/api/v1';
var captchaKey ="6Ld52lwUAAAAAK2d3wNe0EZBfqWrCHsXnZHYyc9y";
var gATrackingID = 'UA-110024236-1';
var fileTypeAllowed = [ 'bmp', 'jpg','jpeg', 'png', 'gif', 'pdf', 'doc', 'docx'];
const hostname = window && window.location && window.location.hostname;
// // // console.log("host name");
// // // console.log(hostname);

if (hostname.indexOf('stsearches.co.uk') > -1 ||
    hostname.indexOf('oagndl2im7rgw') > -1) {
    //file = require('./config.uat');
    apiGateway = 'https://gateway.stsearches.co.uk/api/v1';
    captchaKey = '6LfFjUIUAAAAAHilUi-LX2bRfW1Mn0WYDheJmc1v';
    gATrackingID = 'UA-110024236-2';
}
else if (hostname.indexOf('severntrentsearches.com') > -1 ||
    hostname.indexOf('hdldhjnwh4scs') > -1) {
    //file = require('./config.prod');
    apiGateway = 'https://gateway.severntrentsearches.com/api/v1';
    captchaKey = '6LfIjUIUAAAAABURwQ1dwaQ81polVDDZQgcY8HpX';
    gATrackingID = 'UA-110024236-1';
}
else if (hostname.indexOf('stpropertysolutions.co.uk') > -1) {
    //file = require('./config.azuredev');
    apiGateway = 'http://gateway.stpropertysolutions.co.uk/api/v1';
    captchaKey = '6LefkUIUAAAAAP3fatoX5fXAPRNG372-FjGxgjs5';
    gATrackingID = 'UA-110024236-3';
}
else if (hostname.indexOf('ci-app-web') > -1) {
    //file = require('./config.azuredev');
    apiGateway = 'http://ci-api-gateway.atlas-sts.net/api/v1';
    captchaKey = "6LdqFDkUAAAAAB11dE1SwfAvCpumebIEfvuTL_IL";
    gATrackingID = 'UA-110024236-1';
}
else if (hostname.indexOf('atlas-sts.net') > -1) {
    //file = require('./config.azuredev');
    apiGateway = 'http://api.atlas-sts.net/api/v1';
    captchaKey = "6LdqFDkUAAAAAB11dE1SwfAvCpumebIEfvuTL_IL";
    gATrackingID = 'UA-110024236-1';
}
else if (hostname.indexOf('uat-api-atlas') > -1 || hostname.indexOf('uat-app-atlas') > -1) {
    //file = require('./config.uat');
    apiGateway = 'https://uat-api-atlas-gateway-tf01.azurewebsites.net/api/v1';
    captchaKey = '6LfFjUIUAAAAAHilUi-LX2bRfW1Mn0WYDheJmc1v';
    gATrackingID = 'UA-110024236-2';
}
/*else {
    //backendHost = process.env.REACT_APP_BACKEND_HOST || 'http://localhost:8080';
    //file = require('./config.dev');
}*/

export default {

    webApiUrl : `${apiGateway}/ordering`,
    webApiIdentityUrl : `${apiGateway}/identity`,
    webApiUrlAddressSearch : `${apiGateway}/gbg`,
    webApiUrlAlerts : `${apiGateway}/external`,
    webApiLoginUrl: `${apiGateway}/authorization`,
    captchaKey : captchaKey,
    gATrackingID: gATrackingID,
    fileTypeAllowed: fileTypeAllowed,
    version: window.version,
    envSettings: window.envSettings
}


//var file;
/*// // console.log(process.env.NODE_ENV)
if (process.env.NODE_ENV.trim() === 'uat') {
    file = require('./config.uat');
}
else if (process.env.NODE_ENV.trim() === 'production') {
    file = require('./config.prod');
}
else{
    file = require('./config.dev');
}*/

//export default file.config;// = config;
